import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {RouterProvider} from "./services/routes/router";
import {Global} from "./styles/globals";
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme/theme";
import "./styles/index.css";
import 'leaflet/dist/leaflet.css';
import {EventProvider} from "./services/event";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {SnackbarProvider} from "notistack";

import "react-datepicker/dist/react-datepicker.css";
import {SnakbarComponent} from "@src/components/snakbar";
import {LocalStorageProvider} from "@src/data/common/provider";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const client = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            networkMode: 'always'
        },
    },
})
root.render(
    <>
        <Global/>
        <LocalStorageProvider>
            <EventProvider>
                <QueryClientProvider client={client}>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider maxSnack={3}
                                          autoHideDuration={3000}
                                          action={(snackbarId) => snackbarId}
                                          Components={{
                                              success: SnakbarComponent
                                          }}>
                            <BrowserRouter>
                                <RouterProvider/>
                            </BrowserRouter>
                        </SnackbarProvider>
                    </ThemeProvider>
                </QueryClientProvider>
            </EventProvider>
        </LocalStorageProvider>

    </>,
);

import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { FC, lazy, Suspense } from "react";

export const ROUTES = {
  MAIN: "/",
  MAP: "/map",
  HISTORY: "/history",
  LIBRARY: "/library",
};

/**
 * Page lazy loading
 * @param Component
 * @constructor
 */
const Loader = (Component: FC) => (props: any) => (
  <Suspense fallback={<></>}>
    <Component {...props} />
  </Suspense>
);

/**
 * Pages
 **/
const MapPage = Loader(lazy(() => import("../../pages/main")));
const HistoryPage = Loader(lazy(() => import("../../pages/history")));
const LibraryPage = Loader(lazy(() => import("../../pages/library")));
// const NotFoundPage = Loader(lazy(() => import('../pages/NotFoundPage')))

/**
 * Layouts
 **/
const MainLayout = Loader(lazy(() => import("../../layouts/MainLayout")));

/**
 * Router
 **/
const routes: RouteObject[] = [
  {
    element: <MainLayout />,
    children: [
      {
        path: ROUTES.MAIN,
        element: <MapPage />,
      },
      {
        path: "",
        element: <Navigate to={ROUTES.MAIN} />,
      },
      {
        path: "*",
        element: <Navigate to={ROUTES.MAIN} />,
      },
      {
        path: ROUTES.MAP,
        element: <Navigate to={ROUTES.MAIN} />,
      },
      {
        path: ROUTES.HISTORY,
        element: <HistoryPage />,
      },
      {
        path: ROUTES.LIBRARY,
        element: <LibraryPage />,
      },
    ],
  },
];

export const RouterProvider = () => {
  return useRoutes(routes);
};

import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomTheme extends Theme {}

  interface CustomThemeOptions extends ThemeOptions {}

  export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ["IBMPlexMono", "sans-serif"].join(","),
  },
};

export const theme = createTheme(themeOptions);

// import { createTheme, ThemeOptions } from "@mui/material";
//
// export const themeMui: ThemeOptions = {
//   typography: {
//     fontFamily: ["IBMPlexMono", "sans-serif"].join(","),
//   },
//   components: {
//     MuiTypography: {
//       styleOverrides: {
//         root: {
//           fontFamily: ["IBMPlexMono", "sans-serif"].join(","),
//         },
//       },
//     },
//   },
// };
//
// export const theme = createTheme(themeMui);

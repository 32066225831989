import type {PropsWithChildren} from 'react'
import {LocalStorageValues} from "@src/data/common/enums";
import {LocalStorageService} from "@src/data/common/services";

const localStorage = new LocalStorageService()

const LocalStorageProvider = ({children}: PropsWithChildren): JSX.Element | null => {
    const LocalStorageValuesEntries = Object.values(LocalStorageValues)
    LocalStorageValuesEntries.map((value) => {
        try {
            localStorage.get(value)
        } catch (e) {
            localStorage.set(value, null)
        }
    })
    return (
        <>
            {children}
        </>
    )
}

export {
    LocalStorageProvider
}

import {LocalStorageValues} from "@src/data/common/enums";
import {ExceptionService} from "@src/data/interfaces/common/services";
import {EInternalErrors} from "@src/data/interfaces/common/enums";
import {IStorage} from "@src/data/common/interfaces";


class LocalStorageService implements IStorage<LocalStorageValues> {

    private readonly _driver: Storage

    constructor() {
        this._driver = localStorage
    }

    public get<TResponse>(key: LocalStorageValues): TResponse {
        const value = this._driver.getItem(key)
        if (value === null) {
            throw ExceptionService.new({
                status: {
                    code: EInternalErrors.PROPERTY_NOT_FOUND,
                    message: `Property ${key} not found in ${this.constructor.name}`
                }
            })
        }

        return JSON.parse(value) as unknown as TResponse
    }

    public set<TPayload>(key: LocalStorageValues, payload: TPayload): void {
        this._driver.setItem(key, JSON.stringify(payload))
    }

    public remove(key: LocalStorageValues): void {
        this._driver.removeItem(key)
    }

    public clear(): void {
        this._driver.clear()
    }

}

export {LocalStorageService}

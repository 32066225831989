enum EInternalErrors {
  VALIDATION_ERROR = 1000,
  SERVER_ERROR = 1001,
  LOGICAL_ERROR = 1002,

  PROPERTY_ALREADY_EXIST = 1003,
  PROPERTY_NOT_FOUND = 1004,
  PROPERTY_IS_REQUIRED = 1005,
  PROPERTY_IS_INVALID = 1006,

  UNAUTHORIZED = 1007,

  USER_NOT_FOUND = 1008,
  USER_NOT_VERIFIED = 1009,

  CONTENT_NOT_FOUND = 1011,
  CONTENT_FORBIDDEN = 1012,
  INCORRECT_UUID = 1013,
  UNIQUE = 1014,
  DEPENDENCY_ERROR = 1015,
  VALIDATION_CONTAINER_ERROR = 1016,
  VALIDATION_DISLOCATION_ERROR = 1017,

}

export { EInternalErrors }

import React, {LegacyRef} from "react";
import CheckCircleIcon from "@src/components/ui/icons/check_circle";
import {closeSnackbar, SnackbarKey} from "notistack";
import ClosesIcon from "@src/components/ui/icons/closes_icon";

interface ISnakbarComponentProps {
    style: any
    message: string

    action(): SnackbarKey
}

const SnakbarComponent = React.forwardRef((props: ISnakbarComponentProps, ref: LegacyRef<HTMLDivElement>) => {
    return (
        <div
            {...props}
            style={{
                backgroundColor: "white",
                borderRadius: '4px',
                boxShadow: ' 0px 3px 6px 0px rgba(29, 67, 195, 0.05), 0px 12px 12px 0px rgba(29, 67, 195, 0.04), 0px 26px 16px 0px rgba(29, 67, 195, 0.03), 0px 47px 19px 0px rgba(29, 67, 195, 0.01), 0px 73px 21px 0px rgba(29, 67, 195, 0.00)',
                padding: '10px  12px 10px 12px', ...props.style
            }} ref={ref}>
            <div style={{
                marginLeft: '10px',
                borderLeft: 'solid 2px #41B15A',
                display: "flex",

            }}>
                <div style={{padding: '15px 5px 5px'}}><CheckCircleIcon/>
                </div>
                <p>{props.message}</p>
                <button style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                }}
                        onClick={() => closeSnackbar(props.action())}>
                    <ClosesIcon/>
                </button>
            </div>

        </div>
    )
})

export {
    SnakbarComponent
}
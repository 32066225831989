import styled, {createGlobalStyle} from "styled-components";

export const Global = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  html {
    width: 100vw;
    overflow-x: hidden;
  }

  body {
    display: flex;
    flex-direction: column;
    font-family: Manrope, sans-serif;
    background: #F6F6F6;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  header {
    height: 76px;
  }

  main {
    flex-grow: 1;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @font-face {
    font-family: "IBMPlexMono";
    src: local("IBMPlexMono"), url("../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf") format("truetype");
  }

  ::-webkit-scrollbar {
    width: 8px;
    background: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    cursor: pointer !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #f2f4f7;
    border-radius: 16px;
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer !important;
  }
`;

export const GLOBAL_COMPONENT_STYLES = {
    Container: styled.div`
      display: flex;
      flex-direction: row;
      width: 100vw;
      height: 100vh;
    `,

    LeftNavigationBlock: styled.div`
      width: 160px;
      height: 100vh;
    `,

    ContentBlock: styled.div`
      width: 100vw;
      height: 100vh;
    `,

    ContainerWithPadding: styled.div`
      padding: 32px 24px 128px 32px;
      width: 100%;
      height: 100%;
    `,

    DesktopOnly: styled.div``,

    MobileOnly: styled.div``,
};


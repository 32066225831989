

import type { IEventObserver } from '../event-observer'
import {PublisherService} from "../../../data/interfaces/common/services";

interface IEventPayload<T = unknown> {
  name: string
  data?: T
}

class EventPublisher extends PublisherService<IEventObserver, IEventPayload> {

  public override async notify (payload: IEventPayload): Promise<void> {
    for (const observer of this._observers) {
      if (observer.name === payload.name) {
        await observer.update(payload)
      }
    }
  }

}

export { EventPublisher }

export type { IEventPayload }

enum EventList {
    REFETCH_GET_ALL_LIBRARY = "get-all-library",
    REFETCH_GET_ALL_TAGS = "get-all-tags",

    REFETCH_GET_ALL_SOUND_SOURCES = "get-all-sound-sources",
    REFETCH_GET_ALL_DEVICE = "get-all-device",
    REFETCH_DEVICE = "device",
    REFETCH_GET_ALL_EVENTS = "get-all-sound-events",
}

export {EventList};
